var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card mt-3"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('InputGroup',{attrs:{"label-padding":"0px","col1":"3","col2":"9","label":"قیمت خرید"}},[_c('b-form-input',{attrs:{"value":_vm._f("priceinput")(_vm.realValue.purchasePrice),"type":"text","placeholder":"قیمت خرید"},on:{"input":(value) => {
            _vm.realValue.purchasePrice =
              value !== '' ? +value.replace(/\D/g, '') : '';
          }}})],1),_c('InputGroup',{attrs:{"label-padding":"0px","col1":"3","col2":"9","label":"قیمت  همکار","required":""}},[_c('b-form-input',{attrs:{"value":_vm._f("priceinput")(_vm.realValue.unitPrice),"placeholder":"قیمت  همکار","type":"text"},on:{"input":(value) => {
            _vm.realValue.unitPrice =
              value !== '' ? +value.replace(/\D/g, '') : '';
          }}})],1),_c('InputGroup',{attrs:{"label-padding":"0px","col1":"3","col2":"9","label":"قیمت فروش "}},[_c('b-form-input',{attrs:{"value":_vm._f("priceinput")(_vm.realValue.sellPrice),"type":"text","placeholder":"قیمت فروش "},on:{"input":(value) => {
            _vm.realValue.sellPrice =
              value !== '' ? +value.replace(/\D/g, '') : '';
          }}})],1),_c('InputGroup',{attrs:{"label-padding":"0px","col1":"3","col2":"9","label":"قیمت نمایندگی آنلاین "}},[_c('b-form-textarea',{attrs:{"value":_vm.realValue.online_agents_price,"type":"text","placeholder":"قیمت فروش "},on:{"input":(value) => {
            _vm.realValue.online_agents_price = value;
          }}})],1),_c('InputGroup',{attrs:{"label-padding":"0px","col1":"3","col2":"9","label":"تخفیف"}},[_c('Discount',{model:{value:(_vm.realValue),callback:function ($$v) {_vm.realValue=$$v},expression:"realValue"}})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header border-0"},[_c('h5',{staticClass:"card-title"},[_vm._v("قیمت گذاری")])])
}]

export { render, staticRenderFns }